@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

/* ==============================================================
Bootstrap tweaks
===============================================================*/

body,
h1,
h2,
h3,
h4 {
  font-weight: 300;
}

a {
  color: #533f03;
  font-weight: bold;
}

a:hover {
  color: #533f03;
  font-weight: bold;
  /* make sure browsers use the pointer cursor for anchors, even with no href */
  cursor: pointer;
}

.dropdown-item:hover {
  color: $dropdown-link-hover-color;
}

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

/* ==========================================================================
Generic styles
========================================================================== */

/* Error highlight on input fields */
.ng-valid[required],
.ng-valid.required {
  border-left: 5px solid green;
}

.ng-invalid:not(form,app-settings-card,.skip-invalid-border, td, tr, th, div[formgroupname="seatingConfig"]) {
  border-left: 5px solid red;
}

/* other generic styles */

.jh-card {
  padding: 1.5%;
  margin-top: 20px;
  border: none;
}

.error {
  color: white;
  background-color: red;
}

.pad {
  padding: 10px;
}

.w-40 {
  width: 40% !important;
}

.w-60 {
  width: 60% !important;
}

.break {
  white-space: normal;
  word-break: break-all;
}

.readonly {
  background-color: #eee;
  opacity: 1;
}

.footer {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.hand,
[jhisortby] {
  cursor: pointer;
}

/* ==========================================================================
Custom alerts for notification
========================================================================== */
.alerts {
  .alert {
    text-overflow: ellipsis;

    pre {
      background: none;
      border: none;
      font: inherit;
      color: inherit;
      padding: 0;
      margin: 0;
    }

    .popover pre {
      font-size: 10px;
    }
  }

  .toast {
    position: fixed;
    width: 100%;

    &.left {
      left: 5px;
    }

    &.right {
      right: 5px;
    }

    &.top {
      top: 55px;
    }

    &.bottom {
      bottom: 55px;
    }
  }
}

@media screen and (min-width: 480px) {
  .alerts .toast {
    width: 50%;
  }
}

/* ==========================================================================
entity detail page css
========================================================================== */
.row.jh-entity-details > {
  dd {
    margin-bottom: 15px;
  }
}

@media screen and (min-width: 768px) {
  .row.jh-entity-details > {
    dt {
      margin-bottom: 15px;
    }

    dd {
      border-bottom: 1px solid #eee;
      padding-left: 180px;
      margin-left: 0;
    }
  }
}

/* ==========================================================================
ui bootstrap tweaks
========================================================================== */
.nav,
.pagination,
.carousel,
.panel-title a {
  cursor: pointer;
}

.thread-dump-modal-lock {
  max-width: 450px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.btn-primary {
  background-color: #3964FF;

  &:hover {
    background-color: #2F52D1;
  }
}

.btn-outline-primary {
  color: #3964FF;

  &:active {
    background-color: #3964FF !important;
  }
}

.btn-secondary {
  background-color: #828282;

  &:hover {
    background-color: #6B6B6B;
  }
}

/* jhipster-needle-scss-add-main JHipster will add new css style */

body {
  background-color: white;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.hidden {
  visibility: hidden;
}

.form-field-header {
  font-size: 20px;
  color: #2E5BFF;
  font-weight: bold;
}

.form-field-subheader {
  font-size: 20px;
  color: #BDBDBD;
  font-weight: bold;
}

.form-field-content {
  font-size: 20px;
}

.clickable {
  &:hover {
    color: #2e5bff;
    cursor: pointer;
  }
}

.notClickable {
  color: gray;

  &:hover {
    cursor: default;
  }
}


.bgColorGreen {
  background-color: #80F906 !important;
}

.bgColorYellow {
  background-color: #F7F206 !important;
}

.bgColorRed {
  background-color: #CE1738 !important;
}

.colorBlack {
  color: black !important;
}

.colorWhite {
  color: white !important;
}

.flex {
  display: flex;
}

.flex-basis-75 {
  flex-basis: 75%;
  font-size: 20px;
}

.flex-basis-70 {
  flex-basis: 70%;
  font-size: 20px;
}

.flex-basis-55 {
  flex-basis: 55%;
  font-size: 20px;
}

.flex-basis-50 {
  flex-basis: 50%;
  font-size: 20px;
}

.flex-basis-40 {
  flex-basis: 40%;
  font-size: 20px;
}

.flex-basis-35 {
  flex-basis: 35%;
  font-size: 20px;
}


.flex-basis-30 {
  flex-basis: 30%;
  font-size: 20px;
}

.flex-basis-25 {
  flex-basis: 25%;
  font-size: 20px;
}

.flex-basis-20 {
  flex-basis: 20%;
  font-size: 20px;
}

.flex-basis-15 {
  flex-basis: 15%;
  font-size: 20px;
}

.flex-basis-10 {
  flex-basis: 10%;
  font-size: 20px;
}

.border-radius-5 {
  border-radius: 5px;
}

.h-30 {
  height: 30px;
}

.h-54 {
  height: 54px;
}

.fs-30 {
  font-size: 30px;
}

.settings-modal-button {
  width: 200px;
}

.modal-xxl {
  min-width: 95vw;
}


// timepicker
//.ngb-tp-minute {
//  .form-control {
//    padding: 0;
//  }
//}

.invalid-field-col {
  border-left: 5px solid red;
  height: 39px;

  .ng-invalid:not(form) {
    border: none;
  }
}

.sla-indicator {
  border: 1px solid black;
  border-radius: 3px;
  font-size: 10px;
  padding: 5px;
  font-weight: 400;
  min-width: 53px;
  text-align: center;

  &.non-sla {
    border-color: #ce1738;
    color: #ce1738;
    /* border-color: #dc3545;
     color: #dc3545; */
  }

  &.sla {
    border-color: #3964FF;
    color: #3964FF;
  }

  &.fs-15 {
    min-width: 73px;
  }
}
