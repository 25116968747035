/* after changing this file run 'npm run webpack:build' */

/***************************
put Sass variables here:
eg $input-color: red;
****************************/
// Override Boostrap variables
// Import Bootstrap source files from node_modules
@import 'bootstrap/scss/bootstrap';

/* jhipster-needle-scss-add-vendor JHipster will add new css style */

@font-face {
  font-family: 'ta-mgmt-font';
  src: url('./font/ta-mgmt-font.eot?10238618');
  src: url('./font/ta-mgmt-font.eot?10238618#iefix') format('embedded-opentype'),
  url('./font/ta-mgmt-font.woff?10238618') format('woff'),
  url('./font/ta-mgmt-font.ttf?10238618') format('truetype'),
  url('./font/ta-mgmt-font.svg?10238618#ta-mgmt-font') format('svg');
  font-weight: normal;
  font-style: normal;
}

.avitium-color {
  color: #4285F6;
}

.avitium-orange {
  color: var(--avitium-orange)
}

.avitium-bg-color {
  background-color: #4285F6 !important;
}


.demo-icon {
  font-family: "ta-mgmt-font";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  // margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  //  margin-left: .2em;

  /* You can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */

  &.button-appendix {
    width: inherit;
    font-size: 120%;
  }

  &.size-16 {
    font-size: 16px;
  }

  &.size-30 {
    font-size: 30px;
    font-weight: bold;
    margin-left: 0px;
    margin-right: 0px;
  }

  &.size-header {
    font-size: 50px;
  }

  &.avitium-color {
    color: #4285F6;
  }

  &.circle-icon {
    font-size: 30px;
  }
}

.icon-icons8-360-degrees-filled {
  &:before {
    content: "\e800";
  }
}

.icon-icons8-airplane-landing {
  &:before {
    content: "\e801";
  }
}

.icon-icons8-airplane-take-off {
  &:before {
    content: "\e802";
  }
}

.icon-icons8-airplane {
  &:before {
    content: "\e803";
  }
}

.icon-icons8-back {
  &:before {
    content: "\e804";
  }
}

.icon-icons8-bar-chart {
  &:before {
    content: "\e805";
  }
}

.icon-icons8-bell-lyre {
  &:before {
    content: "\e806";
  }
}

.icon-icons8-bus {
  &:before {
    content: "\e807";
  }
}

.icon-icons8-checkmark {
  &:before {
    content: "\e808";
  }
}

.icon-icons8-delete {
  &:before {
    content: "\e809";
  }
}

.icon-icons8-edit-file {
  &:before {
    content: "\e80a";
  }
}

.icon-icons8-error-2 {
  &:before {
    content: "\e80b";
  }
}

.icon-icons8-error {
  &:before {
    content: "\e80c";
  }
}

.icon-icons8-escalator-up {
  &:before {
    content: "\e80d";
  }
}

.icon-icons8-fire-truck {
  &:before {
    content: "\e80e";
  }
}

.icon-icons8-forward {
  &:before {
    content: "\e80f";
  }
}

.icon-icons8-gas-station {
  &:before {
    content: "\e810";
  }
}

.icon-icons8-janitor {
  &:before {
    content: "\e811";
  }
}

.icon-icons8-licence-plate {
  &:before {
    content: "\e815";
  }
}

.icon-icons8-luggage-trolley {
  &:before {
    content: "\e816";
  }
}

.icon-icons8-minus-filled {
  &:before {
    content: "\e817";
  }
}

.icon-icons8-more {
  &:before {
    content: "\e818";
  }
}

.icon-icons8-new-post {
  &:before {
    content: "\e819";
  }
}

.icon-icons8-next-page-filled {
  &:before {
    content: "\e81a";
  }
}

.icon-icons8-note {
  &:before {
    content: "\e81b";
  }
}

.icon-icons8-ok {
  &:before {
    content: "\e81c";
  }
}

.icon-icons8-paper-plane {
  &:before {
    content: "\e81d";
  }
}

.icon-icons8-plus-math {
  &:before {
    content: "\e81e";
  }
}

.icon-icons8-scissor-lift {
  &:before {
    content: "\e81f";
  }
}

.icon-icons8-settings {
  &:before {
    content: "\e820";
  }
}

.icon-icons8-snowflake {
  &:before {
    content: "\e821";
  }
}

.icon-icons8-sort-right-filled {
  &:before {
    content: "\e822";
  }
}

.icon-icons8-subtract {
  &:before {
    content: "\e823";
  }
}

.icon-icons8-synchronize {
  &:before {
    content: "\e824";
  }
}

.icon-icons8-tableware {
  &:before {
    content: "\e825";
  }
}

.icon-icons8-user-filled {
  &:before {
    content: "\e826";
  }
}

.icon-icons8-zuhause {
  &:before {
    content: "\e827";
  }
}

.icon-icons8-truck {
  &:before {
    content: "\e828";
  }
}

.icon-icons8-eye {
  &:before {
    content: "\e829";
  }
}

.icon-icons8-calendar {
  &:before {
    content: "\e82a";
  }
}
